import { useNavigate } from "react-router-dom";
import { lgLogo } from "../../../assets";

const Navbar = ({ navbar }) => {
    const navigation = useNavigate();
    return (
        <div className={`absolute w-full z-10 ${navbar?.style}`}>
            <div className="flex justify-between items-center px-12 py-5">
                <div className="" onClick={() => navigation('/')}>
                    <img src={lgLogo} alt="Logo" className="w-32" />
                </div>
                <ul className="flex gap-7 grow justify-center items-center pt-4">
                    <li className="text-primary-dark text-[15px] cursor-pointer" onClick={() => navigation('/about')}>Who We Are</li>
                    <li className="text-primary-dark text-[15px] cursor-pointer">Our Impact</li>
                    <li className="text-primary-dark text-[15px] cursor-pointer">Business Highlights</li>
                    <li className="text-primary-dark text-[15px] cursor-pointer">Sustainability</li>
                    <li className="text-primary-dark text-[15px] cursor-pointer">Media</li>
                    <li className="text-primary-dark text-[15px] cursor-pointer">Contact</li>
                    <li className="text-primary-dark text-[15px] cursor-pointer">Career</li>
                </ul>
                <div>
                    <div className="flex gap-1 items-center pt-4 text-primary-dark text-[15px] cursor-pointer">
                        <span>English</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;