import { Route, Routes } from "react-router-dom";
import { MainPage as LandingMainPage } from "./landing/pages";
import { AboutPage as LandingAboutPage } from "./landing/pages";

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <LandingMainPage />
      } />
      <Route path="/about" element={
        <LandingAboutPage />
      } />
    </Routes>
  );
}

export default App;
